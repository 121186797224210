<template>
	<el-container>
		<el-header>
			<div class="logo">
				<el-image class="logo-img" :src="logoUrl" v-if="logoUrl" fit="fit"></el-image>
				<el-image class="logo-img" :src="logo" v-else fit="fit"></el-image>
				<span>欢迎注册</span>
			</div>
			<div class="tip">
				已有账号?
				<router-link to="/login">去登录</router-link>
			</div>
		</el-header>
		<el-main>
			<el-image :src="require('../assets/images/login_bg.jpg')" fit="fit"></el-image>
			<div class="whiteBg">
				<div class="title">注册账号</div>
				<div class="list">
					<div class="item">
						<div>
							<i class="el-icon-mobile"></i>
							<input type="text" placeholder="输入手机号码" v-model="account" />
						</div>
					</div>
					<div class="item">
						<div class="align-left">
							<i class="el-icon-lock"></i>
							<input type="text" placeholder="填写验证码" class="codeIput" v-model="captcha" />
							<button class="code" :disabled="disabled" :class="disabled === true ? 'on' : ''" @click="code">{{ text }}</button>
						</div>
					</div>
					<div class="item">
						<div>
							<i class="el-icon-lock"></i>
							<input type="password" placeholder="填写您的登录密码" v-model="password" v-on:keyup.enter="register"/>
						</div>
					</div>
				</div>
				<div class="logon" @click="register">注册</div>
			</div>
		</el-main>
		<el-footer>
			<div>
				版权所有 {{$config.companyName}}
				<span>渝ICP备20006289-1号</span>
				{{$config.appName}}全球汇
			</div>
			<div>Copyright © 2020 buyersvip.com All Rights Reserved</div>
		</el-footer>
	</el-container>
</template>
<script>
import sendVerifyCode from '../mixins/SendVerifyCode';
import { register, registerVerify, getCodeApi } from '../api/user';
import { validatorDefaultCatch } from '../utils/dialog';
import attrs, { required, alpha_num, chs_phone } from '../utils/validate';

export default {
	name: 'Register',
	mixins: [sendVerifyCode],
	data: function() {
		return {
			account: '',
			password: '',
			captcha: '',
			type: 'register',
			logoUrl: '',
			keyCode: '',
			logo: require('@/assets/images/logo_' + this.$config.appvesion + '.png')
		};
	},
	mounted: function() {
		this.getCode();
	},
	methods: {
		getCode() {
			getCodeApi()
				.then(res => {
					this.keyCode = res.data.key;
				})
				.catch(res => {
					this.$dialog.error(res.msg);
				});
		},
		async code() {
			var that = this;
			const { account } = that;
			try {
				await that
					.$validator({
						account: [required(required.message('手机号码')), chs_phone(chs_phone.message())]
					})
					.validate({ account });
			} catch (e) {
				return validatorDefaultCatch(e);
			}
			await registerVerify({
				phone: that.account,
				type: that.type,
				key: that.keyCode
			})
				.then(res => {
					that.$dialog.success(res.msg);
					that.sendCode();
				})
				.catch(res => {
					that.$dialog.error(res.msg);
				});
		},
		async register() {
			var that = this;
			const { account, captcha, password } = that;
			try {
				await that
					.$validator({
						account: [required(required.message('手机号码')), chs_phone(chs_phone.message())],
						captcha: [required(required.message('验证码')), alpha_num(alpha_num.message('验证码'))],
						password: [required(required.message('密码')), attrs.range([6, 16], attrs.range.message('密码')), alpha_num(alpha_num.message('密码'))]
					})
					.validate({ account, captcha, password });
			} catch (e) {
				return validatorDefaultCatch(e);
			}
			register({
				account: that.account,
				captcha: that.captcha,
				password: that.password,
				spread: window.sessionStorage.getItem('spread') || 0
			})
				.then(res => {
					that.$dialog.success(res.msg);
					that.$router.push('/login');
				})
				.catch(res => {
					that.$dialog.error(res.msg);
				});
		}
	}
};
</script>
<style lang="less" scoped>
.el-header {
	height: auto !important;
	width: 80%;
	margin: 36px auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.logo {
		display: flex;
		align-items: center;
		.logo-img{
			// width: 260px;
		}
		span {
			padding-left: 30px;
			font-size: 24px;
			font-weight: 400;
		}
	}
	.tip {
		a {
			color: #fc4141;
			text-decoration: none;
		}
	}
}
.el-footer {
	color: #333;
	text-align: center;
	line-height: 36px;
	padding: 20px 0;
	height: auto !important;
	font-size: 14px;
	span {
		color: #f60;
	}
}
.el-main {
	text-align: center;
	padding: 0;
	position: relative;
	.el-image {
		width: 100%;
	}
	.whiteBg {
		position: absolute;
		transform: translate(-50%, -50%);
		right: 10%;
		top: 50%;
		width: 340px;
		border-radius: 12px;
		background-color: #fff;
		padding: 20px;
		.title {
			font-size: 18px;
			color: #282828;
			text-align: center;
			font-weight: bold;
			display: flex;
			justify-content: space-around;
			.item {
				color: #999999;
				border-bottom: 2px solid #fff;
				padding-bottom: 6px;
			}
			.item.on {
				color: #282828;
				border-bottom-color: #f35749;
			}
		}
		.list {
			.item {
				border-bottom: 1px solid #ededed;
				padding: 30px 0 10px 0;
				display: flex;
				align-items: center;
				i {
					font-size: 20px;
					padding-right: 10px;
				}
				input {
					flex: 1;
					border: none;
					outline: none;
					font-size: 16px;
					line-height: 32px;
				}
				.align-left {
					flex: 1;
					display: flex;
					align-items: center;
					.code {
						background-color: #f35446;
						border-radius: 30px;
						color: #fff;
						line-height: 32px;
						text-align: center;
						border: none;
						outline: none;
						padding: 0 16px;
					}
				}
			}
		}
		.logon {
			font-size: 16px;
			color: #fff;
			font-weight: bold;
			height: 36px;
			border-radius: 30px;
			background: linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
			background: -webkit-linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
			background: -moz-linear-gradient(to right, #f35447 0%, #ff8e3c 100%);
			text-align: center;
			line-height: 36px;
			margin: 32px 0 12px 0;
		}
	}
}
</style>
